<template>
  <div class="live-main">
    <div class="live-system" :class="{ 'live-on-room': room !== null }">
      <!-- modais -->
      <b-modal
        ref="live-print-modal"
        title="Imprimir conversa"
        id="live-print-modal"
      >
        <div class="iframe-content">
          <iframe srcdoc="&nbsp;" ref="live-print-content"></iframe>
        </div>
        <template #modal-footer="{ cancel }">
          <b-button size="sm" variant="secondary" @click="cancel()">
            Cancelar
          </b-button>
          <b-button size="sm" variant="primary" @click="requestPrint()">
            Imprimir
          </b-button>
        </template>
      </b-modal>
      <b-modal
        ref="live-send-file-modal"
        hide-footer
        title="Enviar arquivo"
        id="live-send-file-modal"
      >
        <div class="form-group row">
          <div class="col-md-9">
            <input
              ref="uploadedFile"
              type="file"
              accept=".doc, .png, .jpeg, .jpg, .ogg, .webp, .doc, .docx, .zip, .exe, .pdf"
              hidden
              @change="uploadFile"
            />
            <div class="input-group mb-3">
              <div class="form-control">{{ form.file }}</div>
              <div class="input-group-append">
                <button
                  class="btn btn-primary"
                  type="button"
                  @click="openFileUpload"
                >
                  <template v-if="form.file">Trocar Arquivo</template>
                  <template v-else>Selecionar Arquivo</template>
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <button
              class="btn btn-success w-100"
              :disabled="isSending"
              @click="sendFile"
            >
              Enviar
            </button>
          </div>
          <div class="col-md-12" v-if="uploadProgress > 0">
            <div class="progress">
              <div
                class="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                :style="`width: ${uploadProgress}%;`"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {{ uploadProgress }}%
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <hr />
            <div class="alert alert-warning">
              Arquivos permitidos: jpeg, png, ogg, mp4 (apenas H.264), webp (max
              100kb, 512x512), doc, pdf, zip
            </div>
            <hr />
          </div>
          <div class="col-sm-12">
            <h3 class="title is-3">Gravar Audio</h3>
            <p class="subtitle">
              <strong>Pressione</strong> no migrofone para gravar
            </p>
            <div class="row">
              <div class="col-sm-2">
                <!-- <VueRecordAudio @stream="onStream" :mode="'press'" @result="onResult"/> -->
              </div>
              <div class="col-sm-10">
                <div class="recorded-audio">
                  <div class="recorded-item">
                    <div class="audio-container">
                      <audio
                        :src="audioBlob"
                        controls="controls"
                        style="float: left; margin-top: 4px"
                      ></audio>
                      <button
                        class="live-enter btn-send-audio"
                        @click="sendRecord"
                        v-if="readySend"
                      >
                        <span class="sr-only">Enviar mensagem</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal ref="live-agents" hide-footer title="Selecione um agente">
        <!-- <div>{{$live.departments}}</div> -->
        <div class="live-transfer-list">
          <div class="qt-loader qt-loader-mini" v-if="$live.agentsLoading">
            <b-spinner variant="primary" type="grow" small></b-spinner>
            <b-spinner variant="primary" type="grow" small></b-spinner>
            <b-spinner variant="primary" type="grow" small></b-spinner>
          </div>
          <div class="p-20" v-else-if="!$live.agents.length">
            Não há outros agentes online
          </div>
          <b-list-group v-else>
            <template v-for="agent of $live.agents">
              <b-list-group-item
                button
                :key="agent.id"
                @click="transferToAgent(agent.id)"
              >
                Transferir para <strong>{{ agent.name }}</strong>
              </b-list-group-item>
            </template>
          </b-list-group>
        </div>
      </b-modal>
      <b-modal
        ref="live-departments"
        hide-footer
        title="Selecione um departamento"
      >
        <!-- <div>{{$live.departments}}</div> -->
        <div class="live-transfer-list">
          <div class="qt-loader qt-loader-mini" v-if="$live.departmentsLoading">
            <b-spinner variant="primary" type="grow" small></b-spinner>
            <b-spinner variant="primary" type="grow" small></b-spinner>
            <b-spinner variant="primary" type="grow" small></b-spinner>
          </div>
          <div
            class="p-20"
            v-else-if="!$live.departments.length && !$live.departments.length"
          >
            Não há departamentos cadastrados
          </div>
          <b-list-group v-else>
            <!-- <template v-for="department of $live.departments"> -->
            <template v-for="department of $live.departments">
              <b-list-group-item
                button
                :key="department.id"
                @click="transferToDepartment(department.id)"
              >
                Transferir para <strong>{{ department.name }}</strong>
              </b-list-group-item>
            </template>
          </b-list-group>
        </div>
      </b-modal>
      <b-modal ref="live-mute-modal" hide-footer>
        <template #modal-title> Silenciar por </template>
        <label class="d-block">
          <input
            type="radio"
            name="live_muted"
            value="disable"
            @click="changeMute"
            :checked="this.muteStatus === 'disabled'"
          />
          Desabilitar
        </label>
        <label class="d-block">
          <input
            type="radio"
            name="live_muted"
            value="8_hours"
            @click="changeMute"
            :checked="this.muteStatus === '8_hours'"
          />
          8 horas
        </label>
        <label class="d-block">
          <input
            type="radio"
            name="live_muted"
            value="1_week"
            @click="changeMute"
            :checked="this.muteStatus === '1_week'"
          />
          Um semana
        </label>
        <label class="d-block">
          <input
            type="radio"
            name="live_muted"
            value="1_month"
            @click="changeMute"
            :checked="this.muteStatus === '1_month'"
          />
          Um mês
        </label>
        <label class="d-block">
          <input
            type="radio"
            name="live_muted"
            value="always"
            @click="changeMute"
            :checked="this.muteStatus === 'always'"
          />
          Sempre
        </label>
      </b-modal>
      <!-- modais -->
      <div class="live-inner" v-if="room">
        <chat-conversation :room="room"/>
      </div>
    </div>

    <div class="live-light" v-if="messageInfo">
      <div class="live-light-header">
        <div class="live-light-header-info">
          <div class="l-image">
            <i data-v-066a33e9="" class="fas fa-user"></i>
          </div>
          <div class="user-info-header">
            <strong>{{ messageInfo.username }}</strong>
            <span :title="messageInfo.sentAt">{{
              messageInfo.sentAt | roundTime
            }}</span>
          </div>
        </div>
        <div class="live-light-header-close">
          <a
            class="c-pointer"
            style="margin-right: 20px"
            :href="messageInfo.uri"
            target="_blank"
          >
            <svg viewBox="0 0 24 24" width="24" height="24" class="">
              <path
                fill="#000"
                d="M18.9 10.3h-4V4.4H9v5.9H5l6.9 6.9 7-6.9zM5.1 19.2v2H19v-2H5.1z"
              ></path>
            </svg>
          </a>
          <a
            class="c-pointer"
            style="margin-right: 20px"
            @click="messageInfo = null"
          >
            <svg viewBox="0 0 24 24" width="24" height="24" class="">
              <path
                fill="#000"
                d="M19.8 5.8l-1.6-1.6-6.2 6.2-6.2-6.2-1.6 1.6 6.2 6.2-6.2 6.2 1.6 1.6 6.2-6.2 6.2 6.2 1.6-1.6-6.2-6.2 6.2-6.2z"
              ></path>
            </svg>
          </a>
        </div>
        <div style="clear: both"></div>
      </div>
      <div class="live-light-body">
        <img
          :src="messageInfo.uri"
          class="img-fluid"
          style="max-height: 500px"
        />
        <div class="live-light-left">
          <a
            class="live-light-close c-pointer"
            style="text-align: center"
            @click="backMedia"
          >
            <svg
              viewBox="0 0 30 30"
              width="30"
              height="30"
              style="margin: 0 auto"
            >
              <path
                fill="#fff"
                d="M19.214 21.212L12.865 15l6.35-6.35-1.933-1.932L9 15l8.282 8.282 1.932-2.07z"
              ></path>
            </svg>
          </a>
        </div>
        <div class="live-light-right">
          <a
            class="live-light-close c-pointer"
            style="text-align: center"
            @click="nextMedia"
          >
            <svg
              viewBox="0 0 30 30"
              width="30"
              height="30"
              style="margin: 0 auto"
            >
              <path
                fill="#fff"
                d="M11 21.212L17.35 15 11 8.65l1.932-1.932L21.215 15l-8.282 8.282L11 21.212z"
              ></path>
            </svg>
          </a>
        </div>
        <hr style="margin-bottom: 5px" />
        <div class="list-gallery" style="height: 80px">
          <div
            style="width: 8%"
            v-for="(message, i) in messagesMidia"
            class="list-gallery-item"
            :key="`galery-light_list-item${i}`"
          >
            <div
              :key="`message_item_${message.id}`"
              class="list-gallery-image"
              v-if="message.roomId === room.roomId"
            >
              <div class="list-gallery-image-sub">
                <div class="list-gallery-image-sub-item">
                  <div
                    class="list-gallery-image-sub-imagem"
                    :style="`background-image: url('${message.uri}')`"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="live-popup-item"
      :class="{
        audio: popupFile && popupFile.type === 'audio',
        video: popupFile && popupFile.type === 'video',
      }"
      v-dragglable="popupFile"
      v-if="popupFile"
    >
      <button @click="popupFile = null">
        <span class="sr-only">Fechar popup</span>
        <i class="fas fa-times"></i>
      </button>
      <header>
        <i class="fas fa-ellipsis-h"></i>
      </header>
      <video
        ref="mediaInteractive"
        controls
        :src="popupFile.uri"
        v-if="popupFile.type === 'video'"
      ></video>
      <!-- :type="popupFile.media_type" -->
      <audio
        ref="mediaInteractive"
        controls
        :src="popupFile.uri"
        v-else-if="popupFile.type === 'audio'"
      ></audio>
      <!-- :type="popupFile.media_type" -->
    </div>

    <vue-easy-lightbox
      :visible="galleryURI !== null"
      :imgs="galleryURI"
      :index="galleyIndex"
      @hide="galleryHide"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import ChatConversation from '@/components/chat/ChatConversation.vue';
import Swal from 'sweetalert2';
import BucketService from '@/services/bucket.service';
// import Tags from '@/components/Tags.vue';

export default {
  name: 'Live',
  components: {
    // Tags,
    ChatConversation,
  },
  computed: {
    idleClients() {
      // v-if="room.type === 'idle'"
      const filtred = this.$live.history.filter((room) => room.type === 'idle');

      // console.log('idleClients', filtred);

      filtred.sort((a, b) => {
        const result = new Date(a.createdAt) - new Date(b.createdAt);

        if (result < 0) return -1;

        if (result > 0) return 1;

        return 0;
      });

      return filtred;
    },
    offClients() {
      const filtred = this.$live.history.filter(
        (room) => room.type === 'client_off',
      );

      // console.log('offClients', filtred);

      filtred.sort((a, b) => {
        const result = new Date(a.createdAt) - new Date(b.createdAt);

        if (result < 0) return -1;

        if (result > 0) return 1;

        return 0;
      });

      return filtred;
    },
    actualRoom() {
      return this.oldRoom ?? this.room;
    },
    roomClosed() {
      return this.room.status !== 'OPEN' && !!this.room.closedAt;
    },
    sessionMinutes() {
      const room = this.oldRoom ?? this.room;
      const time = room?.sessionTime;

      if (!time) return 0;

      const value = (this.currentTime - time) / 1000 / 60;

      // return {
      //   currentTime: this.currentTime,
      //   sessionMinutes: this.room.sessionMinutes,
      //   sessionTime: new Date(this.room.sessionTime),
      //   calc: (this.currentTime - this.room.sessionTime) / 1000 / 60,
      // };

      const days = Math.floor(value / 1440);

      if (days >= 1) return `${days} dia${days < 2 ? '' : 's'}`;

      const hours = Math.floor(value / 60);

      if (hours >= 1) return `${hours} hora${hours < 2 ? '' : 's'}`;

      return `${Math.floor(value)} minuto${value < 2 ? '' : 's'}`;
    },
    availableRoomTags() {
      if (!this.room?.tags) return this.$live.accountTags;

      return this.$live.accountTags.filter(
        (tag) => this.room.tags.indexOf(tag) === -1,
      );
    },
    isManager() {
      return (this.$router?.currentRoute?.path || '').indexOf('manage') !== -1;
    },
    fromGlobal() {
      if (this.isManager && this.currentAgent?.id) {
        return `global:${this.currentAgent.id}`;
      }

      return 'global';
    },
    agentsOnCall() {
      // return true;

      if (this.isManager) {
        // return this.currentAgent !== null && this.currentAgent.rooms > 0;

        const agent = this.currentAgent;

        if (agent === null) return false;

        // return agent.rooms > 0;

        const agentId = agent.id;

        return this.$live.history.some(
          (room) => room.status === 'OPEN' && room.agentId === agentId,
        );
      }

      return this.$live.hasOnCall > 0;
    },
    forsaken() {
      return (
        this.$live.history.findIndex((room) => room.type === 'idle') !== -1
      );
    },
    hasFiles() {
      const room = this.oldRoom ?? this.room;

      if (!room) return false;

      const messages = this.fromTranscript
        ? this.$live.associativeTranscriptsMessages
        : this.$live.messages;

      if (!messages || !messages.length) return false;

      const roomId = room.roomId;

      for (let i = 0, j = messages.length; i < j; i += 1) {
        const msg = messages[i];
        if (msg.roomId === roomId && msg.attachments.length) return true;
      }

      return false;
    },
  },
  filters: {
    showTime(data) {
      if (!data[0]) return '--:--';

      const value = (data[0] - new Date(data[1])) / 1000;

      if (value <= 0) return '00:00';

      if (value >= 86400) {
        const days = Math.floor(value / 86400);
        return `${days} dia${days >= 2 ? 's' : ''}`;
      }

      // setTimeout(() => { agent.lastUpdate = value + 1; }, 1000);

      const hours = Math.floor(value / 60 / 60);
      const mins = Math.floor((value / 60) % 60);
      const secs = Math.floor(value % 60);
      return `${hours}:${mins}:${secs}`
        .replace(/\b(\d)\b/g, '0$1')
        .replace(/^00:/, '');
    },
  },
  watch: {
    room(newVal) {
      if (!newVal) return;

      console.log('watch:room');

      if (newVal.tabId) {
        this.currentTab = newVal.tabId;
      } else {
        this.currentTab = null;
      }

      // this.roomTags = newVal?.tags ?? [];

      this.roomTags = newVal.tags;

      const currentTags = this.room.tags;

      console.log('watch:room', { newVal, currentTags });
    },
    oldRoom(newVal) {
      console.log('omni:oldRoom');

      if (newVal && newVal.tabId) {
        this.currentTab = newVal.tabId;
      } else {
        this.currentTab = null;
      }

      console.log('watch:oldRoom', newVal);
    },
    currentTab(newVal) {
      console.log('omni:currentTab');

      const room = this.oldRoom ?? this.room;

      if (!room) return;

      console.log('watch:currentTab', newVal, room.tabId);

      if (newVal !== room.tabId) this.$live.changeTabRoom(room, newVal);
    },
  },
  data() {
    return {
      currentTime: null,
      popupFile: null,
      galleryURI: null,
      galleyIndex: -1,

      changingTabRoom: false,
      currentTab: null,
      currentAgent: null,
      filterAgents: '',
      tabs: [],

      widget: 0,
      audioBlob: null,
      readySend: false,
      isSending: false,
      uploadProgress: 0,
      form: {},
      formData: {},
      data: {},
      dataAudio: null,
      messagesMidia: [],
      indexMidia: 0,
      messageInfo: null,
      oldRoom: null,
      oldMessages: [],

      room: null,

      metaShow: false,
      loadingQueue: false,

      timeoutSound: 0,
      isMuted: false,
      muteStatus: 'disabled',

      tabHistoryIndex: 0,
      pageHistory: 1,

      tabMetaIndex: 0,
      tabFileType: 0,

      fromTranscript: false,
      // transcriptsFirstLoad: false,
      // offlineFirstLoad: false,

      edit: {
        name: { status: null, value: '' },
        phone: { status: null, value: '' },
        mail: { status: null, value: '' },
      },

      editNameStatus: null,
      editPhoneStatus: null,
      editEmailStatus: null,

      editNameValue: '',
      editPhoneValue: '',
      editMailValue: '',

      autoMaskTimeout: 0,

      roomTags: [],
    };
  },
  // beforeDestroy() {
  //   console.log('omni:beforeDestroy');
  // },
  destroyed() {
    // console.log('omni:destroyed');
    this.closeAgent();
  },
  created() {
    this.fetchRoom();
    this.fetchMessages();
  },
  mounted() {
    console.log('omni:mounted');

    this.detectScroll();

    this.$root.$on('live:agents', (agents) => {
      const agentId = this.currentAgent?.id;

      if (agentId) {
        console.log(
          'live:agents',
          agents,
          agents.indexOf(agentId) !== -1,
          this.currentAgent?.rooms,
        );

        if (agents.indexOf(agentId) !== -1 && this.currentAgent?.rooms) {
          this.$live.subscribeAgentRooms(this.currentAgent);
        }
      }
    });

    this.$root.$on('live:open', (room) => {
      console.log('live:open', room);

      this.oldRoom = null;
      this.room = null;
      this.metaShow = false;
      this.tabMetaIndex = 0;

      if (room.type === 'me' || room.type === 'agent-manager') {
        this.tabHistoryIndex = 0;
      }

      this.chooseRoom(room, null);
    });

    // this.$root.$on('live:transfer', (room) => {
    //   console.log('live:transfer', room);
    //   Swal.fire({
    //     title: 'Novo cliente',
    //     text: `O cliente ${room.name} (${room.email ?? room.phone}) foi transferido para você, deseja aceitar?`,
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Sim',
    //   }).then((result) => {
    //     if (result.value) {
    //       // this.room = element;
    //       this.$live.transferAcceptOrReject(room.room_id, true);
    //     } else {
    //       this.$live.transferAcceptOrReject(room.room_id, false);
    //     }
    //   });
    //   if (window.confirm(`O cliente ${room.name} (${room.email ?? room.phone}) foi transferido para você, deseja aceitar?`)) {
    //     this.$live.transferAcceptOrReject(room.room_id, true);
    //   } else {
    //     this.$live.transferAcceptOrReject(room.room_id, false);
    //   }
    // });

    this.$live.on('user-history', (contactId) => {
      if (
        this.room &&
        this.room.contactId === contactId &&
        this.tabMetaIndex === 2
      ) {
        this.tabMetaIndex = 1;
        this.tabMetaIndex = 2;
      }
    });

    this.$live.on('transferred', (element) => {
      if (this.$router.history.current.path.indexOf('/live') === 0) {
        Swal.fire({
          title: 'Novo cliente',
          text: `O atendente ${element.by.username} lhe transferiu o cliente ${element.username}, gostaria de visualizar agora?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
        }).then((result) => {
          if (result.value) {
            // this.room = element;
            this.chooseRoom(this.$live.roomInfo(element.roomId), null);
          }
        });
      } else {
        this.$root.$emit('live:notify', true);
      }
    });

    this.$live.on('joined', (room) => {
      this.chooseRoom(room, null);
    });

    setInterval(() => {
      if (this.$live.ready) this.currentTime = new Date();
    }, 1000);
  },
  methods: {
    fetchRoom() {
      this.$store.dispatch('fetchOmniRoom', this.$route.params.roomId).then(
        (room) => {
          this.loading = false;
          this.room = room;
        },
        (error) => {
          this.content = error;
          console.log(JSON.stringify(error.response.data));
        },
      );
    },
    fetchMessages() {
      this.$store.dispatch('fetchOmniRoomMessages', this.$route.params.roomId).then(
        (response) => {
          this.loading = false;
          this.messages = response.data;
        },
        (error) => {
          this.content = error;
          console.log(JSON.stringify(error.response.data));
        },
      );
    },
    hideImg(evt) {
      console.log('hideImg', evt);
      evt.target.classList.toggle('invalid', true);
    },
    markAsSolved() {
      const room = this.room;

      if (!room) return;

      Swal.fire({
        title: 'Marcar como resolvido?',
        text: '',
        // icon: 'warning',
        icon: 'success',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) this.$live.markAsSolved(room);
      });
    },
    updateTags() {
      this.$live.updateTags(this.room);
    },
    searchAgents(agent) {
      let filter = this.filterAgents.trim();

      if (filter === '') return true;

      filter = filter
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '')
        .toLowerCase();

      const name = agent.name
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '')
        .toLowerCase();

      if (name && name.indexOf(filter) !== -1) return true;

      let status;

      switch (agent.status) {
        case 'AVAILABLE':
          status = 'disponivel';
          break;
        case 'UNAVAILABLE':
          status = 'indisponivel';
          break;
        case 'SHORT_BREAK':
          status = 'pausa curta';
          break;
        case 'BREAK':
          status = 'pausa';
          break;
        case 'LONG_BREAK':
          status = 'pausa longa';
          break;
        case 'OFFLINE':
          status = 'offline';
          break;
        default:
          return false;
      }

      // console.log({ status, filter });

      return status && status.indexOf(filter) !== -1;
    },
    galleryShow(url) {
      // this.galleryURI = url; // [url];
      this.galleryURI = [{ src: url }];
      this.galleyIndex = 0;
    },
    galleryHide() {
      this.galleryURI = null;
    },
    async showFile(file) {
      switch (file.type) {
        case 'image':
          this.galleryShow(file.uri);
          break;
        default:
          this.popupFile = file;
          this.tryAutoPlay(0);
      }
    },
    async tryAutoPlay(retry) {
      const media = this.$refs.mediaInteractive;

      console.log('tryAutoPlay', media);

      if (retry > 5) return;

      if (media) {
        await this.delay(500);
        media.pause();
        media.play();
      } else {
        await this.delay(500);
        this.tryAutoPlay(retry + 1);
      }
    },
    async delay(timeout) {
      return new Promise((resolve) => {
        setTimeout(resolve, timeout);
      });
    },
    requestInjectWidget() {
      const w = window;

      if (w.YupChat || w.widgetLoaded) return;

      w.widgetLoaded = true;

      const inject = document.createElement('script');
      inject.defer = true;
      inject.src = `${this.$live.widget}/yupchat.js`;
      inject.onload = () => {
        const account = this.$store.state.account;
        const widget = w.YupChat();
        widget.config({ align: 'left', title: account.name });
        widget.open(account.id);
      };
      inject.onerror = () => {
        setTimeout(() => {
          w.widgetLoaded = false;
          this.requestInjectWidget();
        }, 200);
      };
      document.body.appendChild(inject);
    },
    sendRecord() {
      this.sendAudio(this.dataAudio);
    },
    sendAudio(audio) {
      this.formData = new FormData();
      const file = audio;
      this.formData.append('file', file);
      this.isSending = true;
      this.formData.append(
        'account_id',
        this.$live.contextApp.$store.state.account.id,
      );
      this.formData.append('visibility', 'public');
      this.formData.append('convert_to', 'ogg');
      this.$forceUpdate();
      console.log(file);

      Promise.all([
        BucketService.uploadFile(this.formData, true, this.callBackProgress),
      ])
        .then(
          (responses) => {
            console.log(responses);
            this.uploadProgress = 0;
            const extension = responses[0].data.extension;
            const media = 'audio/ogg';

            const draft = {
              roomId: this.room.roomId,
              channel: this.room.channel,
              type: 'file',
              uri: responses[0].data.url,
              mediaType: media,
            };

            this.$live.emit('message', draft);
            this.$refs['live-send-file-modal'].hide();
          },
          (error) => {
            this.content = error;
            this.isSending = false;
            console.log(error);
          },
        )
        .finally(() => {
          this.isSending = false;
        });
    },
    onResult(data) {
      this.readySend = true;
      console.log('The blob data:', data);
      console.log('Downloadable audio', window.URL.createObjectURL(data));
      this.dataAudio = data;
    },
    onStream(stream) {
      console.log(stream);
      this.readySend = false;
    },
    callBackProgress(progress) {
      this.uploadProgress = progress;
    },
    sendFile() {
      this.isSending = true;
      this.formData.append(
        'account_id',
        this.$live.contextApp.$store.state.account.id,
      );
      this.formData.append('visibility', 'public');
      Promise.all([
        BucketService.uploadFile(this.formData, true, this.callBackProgress),
      ])
        .then(
          (responses) => {
            console.log(responses);
            this.uploadProgress = 0;
            const extension = responses[0].data.extension;
            let media = 'image/jpeg';

            switch (extension) {
              case 'ogg':
                media = 'audio/ogg';
                break;
              case 'mp4':
                media = 'video/mp4';
                break;
              case 'mpeg':
                media = 'video/mpeg';
                break;
              case 'webp':
                media = 'image/webp';
                break;
              case 'midi':
                media = 'audio/midi';
                break;
              case 'mp3':
                media = 'audio/mp3';
                break;
              case 'zip':
                media = 'application/zip';
                break;
              case 'pdf':
                media = 'application/pdf';
                break;
              case 'xml':
                media = 'application/xml';
                break;
              case 'jpeg':
                media = 'image/jpeg';
                break;
              case 'jpg':
                media = 'image/jpg';
                break;
              case 'png':
                media = 'image/png';
                break;
              default:
            }

            const draft = {
              roomId: this.room.roomId,
              channel: this.room.channel,
              type: 'file',
              uri: responses[0].data.url,
              mediaType: media,
            };

            this.$live.emit('message', draft);
            this.$refs['live-send-file-modal'].hide();
          },
          (error) => {
            this.content = error;
            this.isSending = false;
            console.log(error);
          },
        )
        .finally(() => {
          this.isSending = false;
        });
    },
    openFileUpload() {
      this.$refs.uploadedFile.click();
    },
    uploadFile(event) {
      const reader = new FileReader();
      this.importing = true;
      this.formData = new FormData();
      const file = event.target.files[0];
      this.formData.append('file', file);
      this.form.file = file.name;
      this.$forceUpdate();
    },
    nextMedia() {
      this.indexMidia += 1;
      this.messageInfo = this.messagesMidia[this.indexMidia];
    },
    backMedia() {
      this.indexMidia -= 1;
      this.messageInfo = this.messagesMidia[this.indexMidia];
    },
    callLight(message) {
      this.messageInfo = message;
      this.indexMidia = this.messagesMidia.findIndex(
        (m) => m.id === message.id,
      );
      console.log(this.indexMidia);
    },
    async copyData(title, data) {
      this.$toast.show({
        title: 'Sucesso',
        content: title,
        type: 'success',
      });

      this.$clipboard(data);
    },
    async print() {
      if (this.room) {
        const modal = this.$refs['live-print-modal'];
        const username = this.room.username;

        let roomId;
        let messages;

        if (this.oldRoom) {
          messages = this.$live.associativeTranscriptsMessages;
          roomId = this.oldRoom.roomId;
        } else if (this.fromTranscript) {
          messages = this.$live.associativeTranscriptsMessages;
          roomId = this.room.roomId;
        } else {
          messages = this.$live.messages;
          roomId = this.room.roomId;
        }

        if (!modal) {
          console.error('this.$refs[livePrintModal] not found');
          return;
        }

        await modal.show();

        // console.log('modal', modal);

        const frame = this.$refs['live-print-content'];

        // let content = '<html><head><style>p{border-top:thin solid #e9e8e8;margin:.4em 0 .8em;padding:.4em 0}p+div{margin:.4em 0;clear:both}code{overflow-wrap:break-word;white-space:pre-wrap;word-break:break-word}p>strong{float:left}p>time{float:right;font-size:.7em}</style><style media="print">button{display:none}</style></head><body><button onclick="print()">Imprimir</button>';

        let content = `<html><head><style>
        html{padding:0;margin:0}body{padding:1.2em;margin:0;font-size:.81em}
        .print-name{font-weight:bolder;float:left;width:12em;}
        .print-msg{border-bottom:thin solid #ececec;padding:.3em 0;page-break-inside:avoid;break-inside:avoid}
        .print-msg:last-child{border:none}
        .print-time{display:block;margin:.5em 0 1em 0;text-align:right;font-size:.96em}
        .print-inner{margin:0 0 0 12em}
        .print-msg.agent .print-name{color:#d90505}
        </style>
        </head><body>`;

        for (let i = 0, j = messages.length; i < j; i += 1) {
          const msg = messages[i];

          if (msg.roomId === roomId) {
            let username = '';

            if (msg.username) {
              username = msg.username;
            } else if (msg.from && msg.from.username) {
              username = msg.from.username;
            }

            let message;

            if (msg.attachments?.length) {
              message = `<em>&lt;${msg.attachments[0].name}&gt;</em>`;
            } else {
              message = this.$live.markdown(msg.message, true);
            }

            content += `<div class="print-msg ${
              msg.typeUser === 'AGENT' ? 'agent' : ''
            }">
            <strong class="print-name">
            ${msg.automatic ? '<automática>' : username}
            ${msg.typeUser === 'AGENT' ? ' (agente)' : ''}:
            </strong>
            <p class="print-inner">
            ${message}
            <time class="print-time">
            ${new Date(msg.sentAt).toLocaleString('pt-BR')}
            ${msg.status === 'FAIL' ? ' (não entregue)' : ''}
            </time>
            </p>
            </div>`;
          }
        }

        content += '</body></html>';

        frame.srcdoc = content;
      }
    },
    requestPrint() {
      this.$refs['live-print-content'].contentWindow.print();
    },
    editInput(type) {
      let input;
      let value;

      switch (type) {
        case 'name':
          this.editNameStatus = 'editing';
          input = this.$refs['live-name-input'];
          value = this.editNameValue;
          break;
        case 'phone':
          this.editPhoneStatus = 'editing';
          input = this.$refs['live-phone-input'];
          value = this.$live.maskPhone(this.editPhoneValue);
          break;
        case 'email':
          this.editEmailStatus = 'editing';
          input = this.$refs['live-email-input'];
          value = this.editMailValue;
          break;
        default:
          console.error('Input error');
      }

      if (input) {
        // console.log('input', input);

        // if (input.scrollIntoView) input.scrollIntoView();

        setTimeout(() => {
          input.focus();
          input.value = '';
          input.value = value;
        }, 50);
      }
    },
    saveInput(type) {
      let value;

      if (type === 'name') {
        this.editNameStatus = 'saving';
        value = this.editNameValue;
      } else if (type === 'phone') {
        this.editPhoneStatus = 'saving';
        value = this.$live.maskPhone(this.editPhoneValue);
      } else if (type === 'email') {
        this.editEmailStatus = 'saving';
        value = this.editMailValue;
      }

      // this.$live.updateContact(type, roomId, contactId, value).then((data) => {
      this.$live
        .updateClient(this.room, type, value)
        .then(() => {})
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          if (type === 'name') {
            this.editNameStatus = null;
          } else if (type === 'phone') {
            this.editPhoneStatus = null;
          } else if (type === 'email') {
            this.editEmailStatus = null;
          }
        });
    },
    cancelInput(type) {
      if (type === 'name') {
        this.editNameStatus = null;
      } else if (type === 'phone') {
        this.editPhoneStatus = null;
      } else if (type === 'email') {
        this.editEmailStatus = null;
      }
    },
    goToIndex() {
      // if (this.tabHistoryIndex !== 0) {
      //   // this.room = null;
      //   this.chooseRoom(null, false);
      // }
      this.chooseRoom(null, null);
    },
    goToTranscript() {
      // console.log('this.transcriptsFirstLoad', this.transcriptsFirstLoad);
      // if (!this.transcriptsFirstLoad) {
      //   this.transcriptsFirstLoad = true;
      //   this.$live.requestTranscripts(this.fromGlobal, this.currentAgent);
      // }

      this.$live.requestTranscripts(this.fromGlobal, this.currentAgent);
    },
    goToOffline() {
      // console.log('this.offlineFirstLoad', this.offlineFirstLoad);
      // if (!this.offlineFirstLoad) {
      //   this.offlineFirstLoad = true;
      //   this.$live.requestTranscripts('offline', this.currentAgent);
      // }
      // this.$live.requestTranscripts('offline', this.currentAgent);
    },
    goToForsaken() {
      console.log('Forsaken');
    },
    goToHistory() {
      this.$live.emit('history', {
        page: this.pageHistory,
        status: 'closed',
        all: false,
        perPage: 100,
      });

      this.pageHistory += 1;
    },
    transferToAgent(id) {
      const room = this.room;

      if (!room) return;

      // if (room && window.confirm('Deseja realmente transferir esse cliente?')) {
      //   this.room = null;
      //   this.$live.transferToAgent(room, id);
      //   this.$refs['live-agents'].hide();
      // }

      Swal.fire({
        title: 'Transferir',
        text: 'Deseja realmente transferir esse cliente?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          this.room = null;
          this.$live.transferToAgent(room, id);
          this.$refs['live-agents'].hide();
        }
      });
    },
    transferToDepartment(id) {
      const room = this.room;

      if (!room) return;

      // if (room && window.confirm('Deseja realmente transferir esse cliente?')) {
      //   this.room = null;
      //   this.$live.transferToDepartment(room, id);
      //   this.$refs['live-departments'].hide();
      // }

      Swal.fire({
        title: 'Transferir',
        text: 'Deseja realmente transferir esse cliente?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          this.room = null;
          this.$live.transferToDepartment(room, id);
          this.$refs['live-departments'].hide();
        }
      });
    },
    transferToQueue() {
      const room = this.room;

      Swal.fire({
        title: 'Devolver a fila',
        text: 'Deseja devolver o cliente para fila?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          this.room = null;
          this.$live.transferToQueue(room);
        }
      });
    },
    changeTabRoom(roomId) {
      this.changingTabRoom = true;

      this.$live
        .changeTabRoom(roomId)
        .catch((error) => {
          this.$toast.show({
            title: 'Error',
            content: error,
            type: 'danger',
          });
        })
        .finally(() => {
          this.changingTabRoom = false;
        });
    },
    toggleMeta() {
      this.metaShow = !this.metaShow;
    },
    closeMeta() {
      this.metaShow = false;
    },
    log(info) {
      console.log(info);
    },
    showAgents() {
      if (this.$refs['live-agents']) {
        this.$live.requestAgents(this.currentAgent?.id);
        this.$refs['live-agents'].show();
      } else {
        console.error('$refs[live-agents] is invalid');
      }
    },
    showDepartments() {
      if (this.$refs['live-departments']) {
        this.$live.requestDepartments();
        this.$refs['live-departments'].show();
      } else {
        console.error('$refs[live-departments] is invalid');
      }
    },
    // transfer(userId) {
    //   this.transferToAgent(userId);
    // },
    getMuted(current) {
      const date = new Date();

      if (current === 'always') {
        this.muteTime = 0;
        this.muteStatus = 'always';
        this.isMuted = true;
      } else if (current) {
        if (current.indexOf('8_hours:') === 0) {
          date.setTime(Number(current.substr(8)));
          this.muteTime = date.getTime();
          this.muteStatus = '8_hours';
          this.isMuted = true;
        } else if (current.indexOf('1_week:') === 0) {
          date.setTime(Number(current.substr(7)));
          this.muteTime = date.getTime();
          this.muteStatus = '1_week';
          this.isMuted = true;
        } else if (current.indexOf('1_month:') === 0) {
          date.setTime(Number(current.substr(8)));
          this.muteTime = date.getTime();
          this.muteStatus = '1_month';
          this.isMuted = true;
        } else {
          this.muteTime = 0;
          this.muteStatus = 'disabled';
          this.isMuted = false;
        }
      }
    },
    chooseMute() {
      this.$refs['live-mute-modal'].show();
    },
    changeMute(event) {
      this.$refs['live-mute-modal'].hide();

      let type = event.target.value;
      let time = 0;

      if (type === '8_hours') {
        time = 8 * 60 * 60 * 1000;
        // time = 2 * 1000;
        type = `${type}:`;
      } else if (type === '1_week') {
        time = 7 * 24 * 60 * 60 * 1000;
        // time = 7 * 1000;
        type = `${type}:`;
      } else if (type === '1_month') {
        time = 30 * 24 * 60 * 60 * 1000;
        // time = 30 * 1000;
        type = `${type}:`;
      } else {
        time = '';
      }

      if (time > 0) {
        const date = new Date();
        date.setTime(date.getTime() + time);
        time = date.getTime();
      }

      const data = `${type}${time}`;
      localStorage.setItem('live-mute', data);
      this.getMuted(data);
    },
    next() {
      // const room = this.$live.next();

      // if (room) this.room = room;
      this.$live.next();
    },
    chooseAgent(agent) {
      console.log(
        'chooseAgent#1',
        this.currentAgent !== agent,
        this.currentAgent,
        agent,
      );

      if (this.currentAgent !== agent) {
        const lastAgent = this.currentAgent;

        // Observa as salas do agente atual
        this.currentAgent = agent;
        this.$live.subscribeAgentRooms(agent);

        // Se existir um agente anterior deverá fechar o observador para este
        if (lastAgent) this.$live.subscribeAgentRooms(lastAgent, false);
      }
    },
    closeAgent(onlyRoom = false) {
      console.log('closeAgent', onlyRoom, this.currentAgent);

      if (this.currentAgent) {
        // this.$live.subscribeAgentRooms(this.currentAgent, false);
        const room = this.room;

        if (onlyRoom === false) this.currentAgent = null;

        this.room = null;

        if (room) this.$live.unsubscribeRoom(room);
      }
    },
    chooseRoom(room, fromTranscript) {
      console.log(':chooseRoom', room, ':newMessages', this.$live.newMessages);

      this.closeAgent(true);

      this.oldRoom = null;
      this.editNameStatus = null;
      this.editPhoneStatus = null;
      this.editEmailStatus = null;

      if (room) {
        this.editNameValue = room.username;

        if (room.mobileNumber) {
          this.editPhoneValue = this.$live.maskPhone(room.mobileNumber);
        }

        if (room.email) this.editMailValue = room.email;

        if (fromTranscript !== null) {
          this.$live.requestTranscriptMessages(room, fromTranscript === 'idle');
        } else if (!room.fromOffline && !room.fromIdle) {
          this.$live.open(room, null, true);
        }
      }

      // this.messagesMidia = [];

      // this.$live.messages.forEach((element) => {
      //   if (element && room) {
      //     if ((element.roomId === room.roomId)) {
      //       switch (element.mediaType) {
      //         case 'image/jpeg':
      //           this.messagesMidia.push(element);
      //           break;
      //         case 'image/png':
      //           this.messagesMidia.push(element);
      //           break;
      //         case 'image/jpg':
      //           this.messagesMidia.push(element);
      //           break;
      //         case 'image/webp':
      //           this.messagesMidia.push(element);
      //           break;
      //         case 'image/gif':
      //           this.messagesMidia.push(element);
      //           break;
      //         default:
      //           break;
      //       }
      //     }
      //   }
      // });

      this.room = room;
      this.metaShow = false;
      this.tabMetaIndex = 0;
      this.fromTranscript = fromTranscript;
    },
    chooseTranscript(transcript) {
      this.closeMeta();

      this.oldRoom = transcript;

      if (transcript) this.$live.requestTranscriptMessages(transcript);
    },
    chooseHistory(history) {
      this.closeMeta();

      // const room = this.$live.roomInfo(history.roomId);
      this.oldRoom = history;

      if (history) {
        this.$live.emit('messages', {
          perPage: 80,
          roomId: history.roomId,
        });
      }
    },
    closeHistory() {
      this.oldRoom = null;
    },
    detectScroll() {
      this.$live.on('$scroll', (roomId) => {
        // if (this.room) console.log('$scroll -> detectScroll', this.room.roomId, roomId);

        if (this.room && this.$refs.liveViewItem) {
          if (roomId === this.room.roomId) {
            this.$refs.liveViewItem.triggerScroll();
          }
        }
      });
    },
    finish() {
      const room = this.room;

      if (room && room.roomId) {
        Swal.fire({
          title: 'Finalizar conversa',
          text: 'Tem certeza que gostaria de finalizar essa conversa?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
        }).then((result) => {
          if (result.value) {
            // this.chooseRoom(null, false);
            this.$live.close(room);
          }
        });
      }
    },
    associativeHistory() {
      if (this.room) {
        this.$live.requestTranscripts(this.room);
      }
    },
    userHistory() {
      if (this.room) {
        this.$live.userHistory(this.room.contactId);
      }
    },
    autoMaskPhone() {
      // console.log('1#editPhoneValue', this.editPhoneValue);

      if (this.autoMaskTimeout !== 0) clearTimeout(this.autoMaskTimeout);

      this.autoMaskTimeout = setTimeout(() => {
        this.editPhoneValue = this.$live.maskPhone(this.editPhoneValue);

        // console.log('2#editPhoneValue', this.editPhoneValue);
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
.mark-as-solved {
  width: 100%;
  margin-bottom: 1em;

  &,
  i {
    color: #fff;
  }
}

.live-btn {
  font-size: 9pt;
  padding: 4px 20px;
  height: 32px;
}

.live-popup-item {
  position: fixed;
  left: 70px;
  bottom: 4px;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.7);
  display: none;
  color: #fff;
  border-radius: 0.2em;
  width: 280px;

  @media (max-width: 992px) {
    left: 4px;
  }

  & > header {
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12pt;
    color: #fff;
    font-weight: bold;

    i,
    i::before {
      color: #fff;
    }
  }

  & > button {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 0 4px 4px 0;
    border: none;
    position: absolute;
    z-index: 1003;
    right: -32px;
    top: 16px;
    font-size: 12pt;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    &,
    & > i {
      color: #fff;
      padding: 0;
      margin: 0;
    }
  }

  .live-popup-sub {
    position: relative;
    z-index: 1001;
    overflow: hidden;
  }

  &.audio {
    display: block;
    height: 72px;

    audio {
      margin-left: 2%;
      width: 96%;
    }
  }

  &.video {
    display: block;
    height: 180px;

    video {
      width: 100%;
      height: 100%;
    }
  }

  video,
  audio {
    position: relative;
    z-index: 1002;
  }
}

::v-deep .tags {
  .b-form-tags.form-control {
    padding: 0.187rem 0.36rem;
  }

  .b-form-tag {
    font-size: 75%;
    font-weight: 400;
    line-height: 1.5;
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .b-form-tag-remove {
    color: inherit;
    font-size: 125%;
    line-height: 1;
    float: none;
    margin-left: 0.25rem;
  }
}

::v-deep button {
  outline: none;
}

#live-print-modal .iframe-content {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

::v-deep #live-print-modal .modal-dialog {
  @media (min-width: 576px) {
    max-width: 90%;
  }

  min-width: 240px;

  .modal-content {
    min-height: 210px;
    height: 80vh;

    .modal-body {
      padding: 0;
    }

    iframe {
      height: 100%;
      width: 100%;
      border: none;
    }
  }
}

$border-color: #e8e4e4;

.live-list-files {
  height: auto !important;

  &::v-deep .tab-content {
    display: none !important;
  }

  &::v-deep .live-list-files {
    height: 52px !important;
  }
}

.live-meta::v-deep .nav-tabs {
  margin-bottom: 0 !important;
}

.live-no-files {
  padding: 2em;
  text-align: center;
  font-size: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.live-grid-files {
  padding: 0;
  margin: 0;
  overflow: auto;
  overflow-x: hidden;
  height: calc(100% - 52px);

  .col-4 {
    height: 100px;
    padding: 0.05em;

    /*
    &:first-child button {
      border-radius: 14px 0 0 0;
    }

    &:first-child button {
      border-radius: 14px 0 0 0;
    }
    */

    & ~ .live-no-files {
      display: none;
    }
  }

  a,
  button {
    height: 100%;
    width: 100%;
    border: none;
    overflow: hidden;
    padding: 0;
    position: relative;
    background: #a0a0a0;
    display: block;

    & > img,
    & > audio,
    & > video,
    .live-file-doc {
      min-width: 100%;
      min-height: 100%;
    }

    .live-file-doc {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24pt;
      font-weight: bold;
      text-transform: uppercase;
    }

    & > i {
      font-size: 80%;
      color: #fff;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
      position: absolute;
      bottom: 5px;
      left: 5px;
    }

    & > span {
      position: absolute;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.2);
      width: 100%;
      height: auto;
      padding: 0.5em;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 70%;
      text-align: right;
      overflow: hidden;
      direction: rtl;
      opacity: 0.001;
      color: #fff;
      font-weight: bold;
      transition: ease-out 0.2s;
    }

    &:hover > span {
      opacity: 0.99;
    }
  }
}

.live-load {
  text-align: center;
  padding: 24px 0;

  ::v-deep .spinner-grow {
    margin: 0 5px;
    animation-duration: 0.45s;
  }
}

.live-main {
  margin: -48px 0 0 -81px;
  overflow: hidden;
  width: calc(100% + 81px);
  height: 100vh;

  @media (max-width: 800px) {
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
  }
}

.live-system {
  min-width: 320px;
  width: 100%;
  height: 100%;
  padding: 62px 0 0 62px;
  background: #fff;

  display: flex;
  flex-direction: row;
  flex: 1;

  &::v-deep strong {
    font-weight: bold !important;
  }

  .live-agent-status {
    vertical-align: middle;
    display: inline-block;
    border-radius: 8px;
    height: 8px;
    width: 8px;
  }

  .live-agents-list {
    overflow: auto;
    overflow-x: hidden;
    height: calc(100% - 43px);
  }

  .live-menu ::v-deep .live-menu-contents {
    height: calc(100% - 80px);

    .filter-agents {
      background: #fff;
      border-bottom: thin solid #e8e8e8;

      input {
        border: none !important;
        border-radius: none !important;
        height: 42px !important;
      }
    }

    .tabs {
      height: 100%;
    }

    .dropdown-item {
      span {
        padding-left: 0.5em;
      }

      span,
      i {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  .live-meta ::v-deep .tabs {
    height: calc(100% - 180px);
  }

  ::v-deep .tab-content {
    height: calc(100% - 52px);
  }

  ::v-deep .tab-pane {
    height: 100%;
  }

  .live-menu::v-deep .nav-tabs {
    margin-bottom: 0 !important;
    display: none;
  }

  .dropdown::v-deep {
    .dropdown-menu {
      left: 1px !important;
      width: calc(100% - 1px) !important;
      margin: 0 !important;
      border-radius: 0 !important;
    }

    .btn.dropdown-toggle {
      border-radius: 0 !important;
      margin: 0;
      padding: 0 1.5em;
      height: 52px;
      font-weight: bold;
      color: #7c7c7c !important;
      background: #fff !important;
      text-align: left;
      position: relative;

      &,
      &:focus,
      &:hover {
        box-shadow: none !important;
        border: none !important;
        border-bottom: 1px solid #e8e4e4 !important;
      }

      & > div > .badge,
      &::after {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }

      & > div > .badge {
        right: 25px;
      }
    }
  }

  ::v-deep .nav-tabs .nav-link {
    padding-bottom: 14px;
    padding-top: 14px;
    height: 52px;

    &.active {
      border-bottom: 4px solid #0e1421 !important;
    }
  }

  ::v-deep [class^='live-'] > header,
  ::v-deep [class*=' live-'] > header {
    border-bottom-width: thin !important;
    height: 80px;
  }

  ::v-deep [class^='live-'] > header,
  ::v-deep [class*=' live-'] > header,
  ::v-deep [class^='live-'] > footer,
  ::v-deep [class*=' live-'] > footer {
    background-color: #fff;
  }

  @media (max-width: 992px) {
    padding-left: 0;
  }

  @media (max-width: 800px) {
    &.live-on-room {
      .live-menu {
        flex: 0 0 0 !important;
      }
      .live-inner {
        flex: 0 0 100% !important;
      }
    }
  }
}

.live-menu,
.live-meta,
.live-inner {
  transition: 0.2s ease;
  overflow: hidden;
}

::v-deep .live-photo {
  padding: 0 !important;
  margin: 20px 0 0 0;
  background: #ccc;
  height: 80px;
  width: 80px;
  border: none;
  border-radius: 50%;
  font-size: 32pt;
  margin-bottom: 5px;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  border-radius: 40px;
  overflow: hidden;

  &,
  * {
    text-align: center;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.live-meta-close {
  width: 36px;
  height: 36px;
  border: none;
  background: transparent;
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 16pt;
  display: none;

  @media (max-width: 1230px) {
    display: block;
  }
}

.live-menu,
.live-meta,
.live-system ::v-deep header[class*='live-'],
.live-system ::v-deep footer[class*='live-'] {
  border: 0 solid $border-color;
}

.live-menu {
  // float: left;
  width: 300px;
  flex: 0 0 300px;
  height: 100%;
  border-right-width: thin;

  @media (max-width: 800px) {
    flex: 0 0 100%;
  }

  header {
    background: #0e1421 !important;
    color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 18px;
    overflow: hidden;

    .live-reconnecting {
      i {
        margin-right: 10px;
        font-size: 20pt;
        vertical-align: middle;
      }
    }

    div {
      flex: 1 0 auto;
      padding: 0 5px;
      font-size: 9pt;

      h3 {
        position: relative;
        font-weight: bold;
        font-size: 12pt;
        margin: 0;
        color: #fff;

        .live-agent-status {
          position: absolute;
          right: 0;
          top: 50%;
          margin-top: -2px;
        }

        .trimmed {
          vertical-align: middle;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          display: inline-block;
          max-width: 210px;
          width: 100%;
        }
      }
    }

    .live-sound {
      flex: 0 0 24px;
      border: none;
      background: transparent;

      i {
        color: #fff;
      }
    }

    .live-next,
    .live-next-request {
      flex: 0 0 110px;
    }

    .live-next-request {
      text-align: center;

      & > span {
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  ::v-deep .nav-tabs .nav-item {
    width: 50%;
  }
}

.live-transcript-load-more {
  border-radius: 36px;
  background: #fff;
  border: 2px solid #0e1421;
  color: #0e1421;
  margin: 16px 10%;
  width: 80%;
  padding: 0.7em 0;
  font-size: 9pt;
  text-transform: uppercase;
  font-weight: bold;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

.live-tab-queue,
.live-tab-history,
.live-tab-offline,
.live-tab-forsaken {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.agent-profile {
  background: #ececec;
  border-radius: 16px;
  display: inline-block;
  height: 32px;
  width: 32px;
  padding-top: 4px;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.live-choose {
  position: relative;
  padding: 0;
  margin-bottom: 0px;
  background: transparent;
  display: block;
  border: 0 solid transparent;
  border-right-width: 2px;
  width: 100%;
  border-radius: 0px;
  text-align: left;
  transition: 0.3s all ease-out;
  outline: 0px;

  &:hover {
    background: #efefef;
  }

  &[disabled] {
    background: transparent;
    opacity: 0.5;
  }

  &.active {
    background: #ececec;
    border-right-color: #0e1421;
  }

  .items {
    margin: 0 !important;
    height: 85px;
    // height: 185px;
  }

  .item-left {
    padding: 0 10px;
    float: left;
    width: 70px;
    height: 100%;
    position: relative;

    & > picture,
    & > img,
    & > i {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    & > picture {
      font-size: 0;
      padding: 0;
      margin: 0;
      overflow: hidden;
      height: 54px;
      width: 54px;
      display: block;
      border-radius: 22px;

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;

        &.invalid {
          display: none;
        }
      }

      img + img {
        display: none;
      }

      img.invalid + img {
        display: inline;
      }
    }
  }

  .item-right {
    border-bottom: 1px solid #e8e8e8;
    padding: 1.6em 0.8em 0.6em 0;
    margin-left: 70px;
    height: 100%;
  }

  h4 {
    font-size: 10pt;
    font-weight: bold;
    width: 88%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .agent-rooms {
    margin-right: 5px;
    display: inline-block;
    color: #c0c0c0;
    font-size: 7pt;
  }

  .time-message-item {
    font-size: 8pt;
    position: absolute;
    right: 8px;
    top: 8px;
    min-width: 56px;
    text-align: center;

    &.badge {
      color: #fff;
      background: #5c6166;
      padding: 0.05em 0.35em !important;
    }
  }

  .news-message {
    border-radius: 64px;
    padding: 0.3em 0.7em;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    font-size: 8pt;
    position: absolute;
    right: 0.8em;
    top: 47%;
    transform: translateY(-50%);
    min-width: 22px;
    min-height: 22px;
    text-align: center;
  }

  .message-footer {
    text-align: right;
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 0.3em 0.5em;
  }

  .last-message-item {
    font-size: 9pt;
    color: #a1a1a1;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    width: 90%;
    overflow: hidden;
  }

  .last-message-icon {
    background-color: #eee;
    border-radius: 10px;
    display: inline-block;
    margin-right: 0.5em;
    padding: 0.2em 0.8em;
  }
}

.live-meta {
  // float: right;
  background: #fff;
  flex: 0 0 300px;
  height: 100%;
  border-left-width: thin;

  .session {
    margin-right: 8px;
    display: inline-block;
    background: #ccc;
    border-radius: 9px;
    height: 18px;
    width: 18px;
    vertical-align: middle;
  }

  .session-time {
    vertical-align: middle;
  }

  @media (max-width: 1230px) {
    // box-shadow: 0 2px 6px rgba(0, 0, 0, .3);
    flex: 0 0 0;
    z-index: 100;
    position: fixed;
    top: 62px;
    right: 0;
    width: 0;

    & > * {
      min-width: 300px;
    }

    &.live-meta-show {
      box-shadow: 0 22px 25px 9px rgba(0, 0, 0, 0.2);
      height: calc(100% - 62px);
      width: 300px;

      @media (max-width: 700px) {
        width: 100%;
      }
    }
  }
}

.live-inner {
  background-color: #f8f8f8;
  height: 100%;
  width: 100%;

  @media (max-width: 700px) {
    width: 0;
    flex: 0 0 0;
  }
}

.live-info {
  padding: 14px;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;

  h3 {
    font-size: 11pt;
    font-weight: bold;
  }

  dl {
    padding: 0.4em 0;
    margin: 0;

    dt {
      font-size: 10pt;
    }

    dd {
      margin-bottom: 2em;
      padding: 0.2em 0.2em 0.2em 0.2em;

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }

      &,
      > * {
        font-size: 10pt;
      }
    }

    .copy {
      input {
        padding: 0;
        background: transparent;
        border: none;
        margin-right: 5px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      button {
        border-radius: 50px !important;
        border: thin solid #ccc;
      }
    }
  }
}

.live-meta-header {
  height: 180px !important;
  position: relative;
  text-align: center;
}

.live-home {
  flex: 0 0 calc(100% - 300px);
  height: 100%;
  overflow: hidden;
  background: #fafafa;

  .live-simulate-client {
    margin: 0 auto;
    display: block;
    border-radius: 5em;
    border: none;
    padding: 0.8em 1.4em;
    color: #0e1421;
    border: 0.18em solid #0e1421;
    text-align: center;
    transition: 0.2s all ease;

    &:hover {
      background: #0e1421;
      color: #fff;
    }

    &:disabled,
    &[disabled='disabled'] {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.3;
    }

    i {
      color: inherit;
      margin-left: 0.2em;
    }
  }

  & > div {
    position: relative;
    height: 100%;
  }

  .live-home-inner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .live-home-img {
    position: relative;
    left: 0;
    margin-bottom: 32px;
    pointer-events: none;
    max-width: 240px;
    width: 100%;
  }

  .live-home-agents {
    font-size: 150pt;
    color: #dfdfdf;
  }

  @media (max-width: 800px) {
    display: none;
  }
}

.live-header-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
  width: 262px;
  font-size: 13pt;
  font-weight: 500;
  margin: 0 auto;
}

.live-meta-header {
  & > div:first-child,
  & > div:first-child + div {
    height: 100%;
  }

  & > div:first-child {
    width: 60px;
    float: left;
    text-align: center;
    padding-top: 22px;
  }

  & > div:last-child {
    text-align: center;
  }

  & > div:first-child + div {
    float: right;
    width: 42px;
  }
}

.live-photo-profile {
  max-width: 50px !important;
  display: block;
  margin: 0 auto;
}

.live-no-ready {
  text-align: center;
  padding: 2em 0;
  pointer-events: none;
  height: 100%;
  display: flex;
  max-width: 200px;
  margin: 0 auto;
  align-items: center;

  h4 {
    margin-top: 20px;
    font-size: 10pt;
    display: inline-block;
    background: #eee;
    padding: 5px 10px;
    border-radius: 20px;
  }
}

::v-deep .dropdown-menu {
  top: auto !important;
  left: auto !important;
  transform: none !important;
}

.field-edit {
  margin-top: 1em;

  h3 {
    font-weight: normal;
    color: #c0c0c0;
  }

  button {
    border-radius: 25px !important;
    border: thin solid #c0c0c0;
    padding: 0.05em 1.5em;
  }

  .read + button {
    visibility: hidden;
  }

  &:hover button {
    visibility: visible;
  }

  input {
    padding: 0.8em;
    margin-right: 6px;

    &.read {
      border: none;
      background: transparent;
    }

    &.edit {
      border-radius: 25px;
    }
  }
}

.live-history-item {
  background: #fff;
  border-radius: 0.4em;
  border: thin solid #ccc;
  margin: 0.4em 1em 0.4em 0;
  padding: 0.6em;
  position: relative;
  width: 97%;
  text-align: left;
  transition: 0.15s all ease;

  &:hover {
    background: #ececec;
  }

  p {
    margin: 0;
    padding: 0.05em 2em 0.05em 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  time {
    font-size: 80%;
    color: #c0c0c0;
    display: block;
    text-align: right;
  }

  .channel {
    border: thin solid #ccc;
    border-radius: 50px;
    height: 32px;
    width: 32px;
    position: absolute;
    right: -16px;
    top: 25%;
    background: #fff;
    text-align: center;

    & + p + p {
      color: #a1a1a1;
    }

    i {
      position: relative;
      margin-top: 50%;
      width: 16px;
      height: 16px;
      top: -8px;
    }
  }
}

.live-old-history {
  border: solid #f7d85c;
  border-width: thin 0;
  position: sticky;
  top: 0;
  z-index: 100;
  background: #ffefad;

  .items {
    height: 60px;
  }

  .item-left {
    position: relative;

    & > i {
      font-size: 18pt;
    }
  }

  .item-right {
    border-bottom: none;
  }

  * {
    color: #bb9600;
  }
}

.live-history-item {
  &.active {
    border-color: #f7d85c;
    background: #ffefad;

    &:hover {
      background: #fde996;
    }

    * {
      color: #bb9600;
    }

    .channel {
      border-color: #bb9600;
    }
  }
}

#departments-live-modal {
  &::v-deep {
    .card-header {
      display: block;

      & > button {
        text-align: left;
      }
    }
    .card-body,
    .card-header {
      padding: 0 !important;
      height: auto !important;
      min-height: 0 !important;
    }
  }
}

.live-transfer-list {
  overflow: auto;
  overflow-x: hidden;
  max-height: 80vh;
}

.live-system::v-deep .qt-loader {
  text-align: center;
  padding: 1em;
  z-index: 99;

  .spinner-grow {
    margin: 0 0.08em;
  }
}

.live-light {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 2000;
  background: #fffffffa;
}
.live-light-close {
  background: #0000094a;
  width: 50px;
  height: 50px;
  color: #000;
  display: flex;
  border-radius: 50%;
  box-shadow: 1px 1px 2px 0.2px #aeaeae;
  align-items: center;
  align-content: center;
  text-align: center;
}
.live-light-close:hover {
  background: #ccc;
}
.live-light-close i {
  color: #fff;
  display: block;
  margin: 0 auto;
}
.live-light-header {
  background: #fff;
}
.live-light-header-close {
  padding: 10px;
  float: right;
}
.live-light-header-info {
  float: left;
  padding: 10px;
}
.live-light-header-info .l-image {
  background: #ccc;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  float: left;
  vertical-align: middle;
  padding-top: 10px;
}
.live-light-header-info .l-image i {
  font-size: 25px;
  align-self: center;
  vertical-align: middle;
}
.live-light-header-info .l-image img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.live-light-header-info .user-info-header {
  float: left;
  margin-left: 10px;
}
.live-light-header-info .user-info-header strong {
  display: block;
}
.live-light-header-info .user-info-header span {
  font-size: 13px;
  margin-top: -5px;
  display: block;
}
.live-light-body {
  text-align: center;
  padding-top: 10px;
  overflow-y: auto;
}
.live-light-body img {
}
.list-gallery {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: left;
  overflow-y: hidden;
  height: 80px;
  padding-bottom: 10px !important;
}
.list-gallery .list-gallery-item {
  position: relative;
  width: 29%;
  height: 100%;
  overflow: hidden;
  margin: 5px;
  border-radius: 10px;
}

.list-gallery .list-gallery-item .list-gallery-image {
  cursor: pointer;
  background-position: 50%;
  background-size: cover;
  transform: none;
  position: absolute;
  top: 0;
  z-index: 1;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  transition: transform 0.08s cubic-bezier(0.09, 0.87, 0.72, 1);
}

.list-gallery .list-gallery-item .list-gallery-image-sub {
  width: 100%;
  height: 100%;
  position: relative;
}
.list-gallery .list-gallery-item .list-gallery-image-sub-item {
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.list-gallery .list-gallery-item .list-gallery-image-sub-imagem {
  cursor: pointer;
  background-position: 50%;
  background-size: cover;
  transform: none;
  position: absolute;
  top: 0;
  z-index: 1;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  transition: transform 0.08s cubic-bezier(0.09, 0.87, 0.72, 1);
}
.live-light-left {
  position: absolute;
  left: 60px;
  top: 40%;
}
.live-light-right {
  position: absolute;
  right: 60px;
  top: 40%;
}
.btn-send-audio {
  background: #4db6ac;
  width: 50px;
  border: none;
  height: 50px;
  border-radius: 50%;
  float: left;
  margin-left: 15px;
  margin-top: 4px;
  cursor: pointer;
}
.btn-send-audio:hover {
  background: #46a79d;
}
.live-enter::before {
  margin: 0 auto;
  display: block;
  content: '';
  height: 50%;
  width: 50%;
  background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 441.30001" version="1.0">%3Cpath fill="%23fff" d="M 39.500001,0.69252634 C 17.300001,5.1925263 1.4042055e-6,26.692526 1.4042055e-6,49.692526 c 0,3.2 5.3999999957945,26.200002 14.5999995957945,62.700004 8.1,31.7 16.7,65.7 19.2,75.4 l 4.4,17.8 H 129.9 c 85.2,0 91.8,0.1 95.1,1.8 10.7,5.3 10.7,21.1 0,26.4 -3.3,1.7 -9.9,1.8 -95.1,1.8 H 38.200001 l -4.4,17.7 c -2.5,9.8 -11.1,43.8 -19.2,75.5 -9.1999996,36.5 -14.5999995957945,59.5 -14.5999995957945,62.7 0,23.8 17.5999995957945,44.9 40.9999995957945,49.1 7.7,1.4 16.7,0.7 24.1,-1.9 12.6,-4.4 418.899999,-173.5 423.799999,-176.4 13.6,-8.1 23.1,-25.3 23.1,-41.7 0,-16.4 -9.5,-33.6 -23.1,-41.7 C 484,175.99253 77.900001,6.9925263 65.000001,2.3925263 c -6.6,-2.29999996 -18.6,-3.09999996 -25.5,-1.69999996 z"/>%3C/svg>')
    center no-repeat;
  background-size: contain;
}
</style>
